import React from 'react';
import Initial from '../components/Initial';
import Features from '../components/Features'; 
function Home() {
    document.title = "Idealizer";
 
  return (
    <div>
      <Initial />
      <Features />

    </div>
  );
}

export default Home;
