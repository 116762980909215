import React, { useState } from 'react';

function Contato() {
  document.title = "Contato Idealizer";
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    celular: '',
    assunto: '',
  });
  const [touched, setTouched] = useState({
    celular: false,
    email: false,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const celularRegex = /^\(\d{2}\)\s9\s\d{4}-\d{4}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const formatCelular = (value) => {
    const cleaned = value.replace(/\D/g, '');
    if (cleaned.length <= 2) return cleaned;
    if (cleaned.length <= 7) return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`;
    if (cleaned.length <= 11) return `(${cleaned.slice(0, 2)}) 9 ${cleaned.slice(3, 7)}-${cleaned.slice(7, 11)}`;
    return `(${cleaned.slice(0, 2)}) 9 ${cleaned.slice(3, 7)}-${cleaned.slice(7, 11)}`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'celular') {
      setFormData({ ...formData, celular: formatCelular(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (celularRegex.test(formData.celular) && emailRegex.test(formData.email)) {
      const googleFormURL = 'https://docs.google.com/forms/d/e/1FAIpQLSf3Mcs3W3m5J38EMqYpXVWUvJojQ07EAOhZ4YTS3upAt9KbjQ/formResponse';
      const params = new URLSearchParams();
      params.append('entry.538211101', formData.nome);  
      params.append('entry.859159927', formData.email); 
      params.append('entry.250286708', formData.celular); 
      params.append('entry.1355880207', formData.assunto); 

      fetch(googleFormURL, {
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(() => {
        setModalMessage('Dados enviados com sucesso!');
        setModalOpen(true);
        setFormData({
          nome: '',
          email: '',
          celular: '',
          assunto: '',
        });
        setTouched({ celular: false, email: false });
      })
      .catch(error => {
        console.error('Erro:', error);
        setModalMessage('Dados enviados com sucesso!');
        setModalOpen(true);
        setFormData({
          nome: '',
          email: '',
          celular: '',
          assunto: '',
        });
        setTouched({ celular: false, email: false });
      });
    } else {
      console.log('Erro na validação');
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Entre em Contato</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label htmlFor="nome" style={styles.label}>Nome Completo:</label>
          <input
            type="text"
            id="nome"
            name="nome"
            value={formData.nome}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="email" style={styles.label}>E-mail:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            style={styles.input}
          />
          {touched.email && !emailRegex.test(formData.email) && (
            <p style={styles.error}>E-mail inválido.</p>
          )}
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="celular" style={styles.label}>Celular:</label>
          <input
            type="tel"
            id="celular"
            name="celular"
            value={formData.celular}
            onChange={handleChange}
            onBlur={handleBlur}
            required
            style={styles.input}
            placeholder="(xx) x xxxx-xxxx"
          />
          {touched.celular && !celularRegex.test(formData.celular) && (
            <p style={styles.error}>Celular inválido. Use o formato (xx) x xxxx-xxxx.</p>
          )}
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="assunto" style={styles.label}>Assunto:</label>
          <textarea
            id="assunto"
            name="assunto"
            value={formData.assunto}
            onChange={handleChange}
            required
            style={styles.textarea}
          ></textarea>
        </div>
        <button type="submit" style={styles.button}>Enviar</button>
      </form>

      {isModalOpen && (
        <div style={styles.overlay}>
          <div style={styles.modal}>
            <p>{modalMessage}</p>
            <button onClick={closeModal} style={styles.button}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    padding: '2rem',
    maxWidth: '700px',
    margin: '0 auto',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease-in-out',
    marginTop: '50px',
    paddingBottom: '100px',
    marginBottom: '400px',
  },
  title: {
    fontSize: '2rem',
    textAlign: 'center',
    marginBottom: '1.5rem',
    color: '#333',
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '1.5rem',
  },
  label: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#555',
    marginBottom: '0.5rem',
    display: 'block',
  },
  input: {
    padding: '0.75rem 1rem',
    fontSize: '1rem',
    border: '1px solid #ccc',
    borderRadius: '8px',
    width: '100%',
    boxSizing: 'border-box',
    transition: 'border-color 0.3s ease',
    outline: 'none',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  },
  textarea: {
    padding: '0.75rem 1rem',
    fontSize: '1rem',
    border: '1px solid #ccc',
    borderRadius: '8px',
    width: '100%',
    resize: 'vertical',
    minHeight: '120px',
    boxSizing: 'border-box',
    transition: 'border-color 0.3s ease',
    outline: 'none',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  },
  button: {
    padding: '0.75rem',
    fontSize: '1.1rem',
    color: '#fff',
    backgroundColor: '#22c55e',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  },
  error: {
    color: 'red',
    fontSize: '0.9rem',
    marginTop: '0.5rem',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: '#fff',
    padding: '2rem',
    borderRadius: '10px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
  },
};

export default Contato;
