import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; 

function Header() {
  return (
    <header>
      <nav style={styles.nav}>
        <Link to="/">
          <img src={logo} alt="Logo" style={styles.logoImage} />
        </Link>
        <ul style={styles.navList}>
          <li><a href="PsicoID">PsicoID</a></li>
          <li><a href="sobre">Sobre</a></li>
          <li><Link to="Contato">Contato</Link></li>
        </ul>
      </nav>
    </header>
  );
}

const styles = {
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1rem 2rem',
    backgroundColor: '#333',
    flexWrap: 'wrap',
  },
  logoImage: {
    width: '150px',
    height: 'auto',
  },
  navList: {
    listStyle: 'none',
    display: 'flex',
    gap: '1rem',
    margin: 0,
    padding: 0,
  },
  navItem: {
    color: '#fff',
    textDecoration: 'none',
  },
};

export default Header;
