import React from 'react';
import { useNavigate } from 'react-router-dom';
import logoIcon from '../assets/logoIcon.png';

function Initial() {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contato');
  };

  return (
    <section style={styles.hero}>
      <img src={logoIcon} alt="App PsicoID" style={styles.logoIcon}/>
      <h1 style={styles.title}>Bem-vindo à Idealizer!</h1>
      <p style={styles.description}>
        Fornecemos soluções tecnológicas que transformam negócios.
      </p>
      <button style={styles.button} onClick={handleContactClick}>
        Entre em contato
      </button>
    </section>
  );
}

const styles = {
  hero: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#031309',
    color: 'white',
    textAlign: 'center',
    padding: '0 2rem',
  },
  title: {
    fontSize: '2.5rem',
    marginBottom: '1rem',
  },
  description: {
    fontSize: '1.25rem',
    marginBottom: '2rem',
  },
  button: {
    padding: '0.75rem 2.5rem',
    backgroundColor: '#22c55e',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    transition: 'background-color 0.3s ease',
  },
  buttonHover: {
    backgroundColor: '#1e7a35',
  },
  logoIcon: {
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    borderRadius: '8px',
    marginBottom: '1rem',
  },
};

export default Initial;
