import React from 'react';

function Features() {
  return (
    <section style={styles.features}>
      <h2 style={styles.title}>Nossos serviços</h2>
      <div style={styles.featureList}>
        <div style={styles.feature}>
          <h3>Desenvolvimento de Apps</h3>
          <p>Desenvolvemos aplicativos IOS e Android para trazer soluções inovadoras ao mercado</p>
        </div>
        <div style={styles.feature}>
          <h3>PsicoID</h3>
          <p>Um exemplo dos nossos projetos, APP para busca e agendamento de psicólogas</p>
        </div>
        <div style={styles.feature}>
          <h3>Consultoria de TI</h3>
          <p>Realizamos consultoria de TI para sua empresa aplicar suas soluções</p>
        </div>
      </div>
    </section>
  );
}

const styles = {
  features: {
    padding: '4rem 2rem',
    backgroundColor: '#f9f9f9',
    textAlign: 'center',
  },
  title: {
    fontSize: '2.5rem',
    marginBottom: '1.5rem',
  },
  featureList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginTop: '2rem',
  },
  feature: {
    flex: '1 1 30%',
    maxWidth: '30%',
    backgroundColor: '#fff',
    padding: '1rem',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    margin: '1rem',
    minWidth: '250px',
  },
};

export default Features;
