
import React from 'react';

function Footer() {
  return (
    <footer style={styles.footer}>
      <div style={styles.container}>
        <p style={styles.text}>CNPJ: 57.325.737/0001-00</p>
        <p style={styles.text}>&copy; 2024 Idealizer LTDA. Todos os direitos reservados.</p>
      </div>
    </footer>
  );
}

const styles = {
  footer: {
    padding: '1rem',
    textAlign: 'center',
    backgroundColor: '#333',
    color: '#fff',
    width: '100%',
    position: 'relative',
    marginTop: '30px',
  },
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
  },
  text: {
    margin: '0.5rem 0',
    fontSize: '0.9rem',
  },
};

export default Footer;
