import React from 'react';
import appImage from '../assets/psicoid-app.png';

function PsicoID() {
  document.title = "PsicoID Idealizer";
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>PsicoID</h1>
      <h2 style={styles.appTitle}>Conheça um de nossos projetos!</h2>

      <div style={styles.appContainer}>
        <img src={appImage} alt="App PsicoID" style={styles.appImage} />
        <div style={styles.appDescription}>
          <h2 style={styles.appTitle}>Seu aliado na busca por bem-estar psicológico, conectando você aos melhores profissionais.</h2>
          <p style={styles.appText}>
            PsicoID é um aplicativo inovador que facilita a busca e agendamento de consultas com psicólogos.
            Oferecemos uma interface amigável e recursos úteis para você cuidar da sua saúde mental.
          </p>
          <a href="https://psicoid.com.br" target="_blank" rel="noopener noreferrer">
            <button style={styles.button}>Acesse o site do APP</button>
          </a>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '2rem',
    textAlign: 'center',
    height: 'auto'
  },
  title: {
    fontSize: '2.5rem',
    marginBottom: '1.5rem',
    color: '#fff',
  },
  appContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '2rem',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  appImage: {
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
    borderRadius: '8px',
    marginBottom: '1rem',
    
  },
  appDescription: {
    textAlign: 'center',
  },
  appTitle: {
    fontSize: '1.8rem',
    color: '#fff',
  },
  appText: {
    fontSize: '1rem',
    color: '#fff',
    margin: '1rem 0',
  },
  button: {
    padding: '0.75rem 1.5rem',
    backgroundColor: '#22c55e',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    color: '#fff',
    fontSize: '1rem',
    transition: 'background-color 0.3s',
    marginTop:'50px'
  },
};

export default PsicoID;
